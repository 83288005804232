<template>
    <div id="content" class="external-tool">
        <template v-if="form">
            <Form :id="externalToolID" @back="closeForm"></Form>
        </template>
        <template v-else>
            <div class="bar-actions">
                <Button :customClass="{ disabled: !hasAccess }" bType="create" :bCallback="addExternalTool" bLabel="external_tools.create_tool"></Button>
            </div>
            <div class="external-tool-container">
                <div class="sidebar-filters" :class="{ 'empty-results': noChanges && Object.values(items).length == 0 }">
                    <FilterExternalTools @noChanges="noChanges = true" @changes="noChanges = false" :isDisabled="noChanges && Object.values(items).length == 0" @resetPageDatatable="top(true)"></FilterExternalTools>
                </div>
                <div class="data-table" :class="{ 'empty-results': noChanges && Object.values(items).length == 0 }">
                    <v-data-table v-if="items" v-model="selected" :headers="headers" fixed-header :items="Object.values(items)" sort-by :sort-asc="true" hide-default-footer :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pageCount = $event">
                        <template #item.icon="item">
                            <div v-if="item.item.icon != 'null'" class="image" :style="{ backgroundImage: 'url(' + item.item.icon + ')' }" @click="openMedia(item.item.icon)"></div>
                        </template>
                        <template #item.name="item">
                            <div @click="goToReport(item.item.id)">
                                <span class>{{ item.item.name }}</span>
                            </div>
                        </template>
                        <template #item.priority="item">
                            <div @click="goToReport(item.item.id)">
                                <span class>{{ item.item.priority }}</span>
                            </div>
                        </template>
                        <template #item.action="item">
                            <div @click="goToReport(item.item.id)">
                                <span class>{{ item.item.action }}</span>
                            </div>
                        </template>
                        <template #item.type="item">
                            <div @click="goToReport(item.item.id)">
                                <span class>{{ item.item.type }}</span>
                            </div>
                        </template>
                        <template #item.status="item">
                            <div class="status" @click="goToReport(item.item.id)">
                                <span class="estate">
                                    <span class="ball-color" :style="[item.item.status == '1' ? { backgroundColor: '#5FC09C' } : { backgroundColor: '#B6B9BE' }]"></span>
                                    <span v-if="item.item.status == '1'">{{ $t('sites.form.active') }}</span>
                                    <span v-else-if="item.item.status == '-2'">{{ $t('sites.form.inactive') }}</span>
                                </span>
                            </div>
                        </template>
                        <template v-slot:no-data>
                            <div class="no-data" v-if="!noChanges && itemsLoaded && items.length == 0">
                                {{ $t('supervise.checklists.table_headers.no_data') }}
                            </div>
                            <div class="no-data" v-else>
                                {{ $t('') }}
                            </div>
                        </template>
                    </v-data-table>
                    <div id="pagination" v-if="!(noChanges && Object.values(items).length == 0)">
                        <div class="totalItems">
                            <span class="total">
                                <strong class="total-results">{{ Object.values(items).length }}</strong>
                                {{ $t('supervise.checklists.table_headers.results') }}
                            </span>
                        </div>
                        <v-pagination v-model="pagination.page" @input="top(false)" color="#2c52c4" :length="pageCount" :total-visible="maxPageVisibles"></v-pagination>
                        <div class="rows-page">
                            <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                        </div>
                    </div>
                    <div class="andy-datatable-empty" v-if="noChanges && itemsLoaded && Object.values(items).length == 0">
                        <EmptyTable @buttonAction="addExternalTool" :buttonText="$t('external_tools.create_tool')" :title="$t('empty_table.users_title')" :description="$t('empty_table.external_tools')"></EmptyTable>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import i18n from '@/i18n'
import FilterExternalTools from '@/components/domain/externalTools/filter'
import Form from '@/components/domain/externalTools/form'
import EmptyTable from '@/components/ui/EmptyTable'

export default {
    components: {
        FilterExternalTools,
        Form,
        EmptyTable
    },
    name: 'ExternalTools',
    data() {
        return {
            noChanges: false,
            externalToolID: undefined,
            form: false,
            values: false,
            singleSelect: false,
            valueFilter: 1,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('product.product_category.order'),
                    align: 'start',
                    sortable: true,
                    value: 'priority',
                    class: 'header-table'
                },
                {
                    text: this.$t('product.icon'),
                    align: 'start',
                    sortable: true,
                    value: 'icon',
                    class: 'header-table'
                },
                {
                    text: this.$t('product.name'),
                    align: 'start',
                    sortable: true,
                    value: 'name',
                    class: 'header-table'
                },
                {
                    text: this.$t('external_tools.type'),
                    value: 'type',
                    align: 'start',
                    class: 'header-table'
                },
                {
                    text: this.$t('external_tools.url'),
                    value: 'action',
                    align: 'start',
                    class: 'header-table'
                },
                {
                    text: this.$t('product.status'),
                    value: 'status',
                    align: 'start',
                    class: 'header-table'
                }
            ]
        }
    },
    computed: {
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        items() {
            var result = { ...this.$store.getters['account/getList']() }
            if (result) {
                return result
            }
            return false
        },
        itemsLoaded() {
            return this.$store.getters['account/getItemsLoaded']
        },
        hasAccess() {
            return this.checkPermission('/tools/add')
        }
    },
    methods: {
        top(cond) {
            if (cond && !this.$route.params.noChangePageDatatable) {
                this.pagination.page = 1
            }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        openMedia(ref) {
            this.$popup.media({ image: ref })
        },
        checkPermission(routePath) {
            var route = { id: 'prueba', path: routePath }
            if (this.$store.getters['hasAccess'](route)) {
                return true
            } else {
                self.$snackbar.open({
                    message: self.$t('external_tools.no_permission'),
                    duration: 5000
                })
            }
        },
        goToReport(id) {
            if (this.checkPermission('/tools/idTool')) {
                this.form = true
                this.externalToolID = id
            }
        },
        addExternalTool() {
            if (this.checkPermission('/tools/add')) {
                this.form = true
                this.externalToolID = false
            }
        },
        closeForm() {
            this.form = false
            this.externalToolID = false
            this.$router.push({
                name: 'ExternalTools',
                params: {
                    noChangePageDatatable: true
                }
            })
        },
        load() {
            var self = this
            self.$overlay.show()
            var items = this.$store.getters['account/getList']()
            var filters = this.$store.getters['account/getFilterExternalTools']
            if (Object.keys(items).length > 0 && Object.keys(filters).length > 0) {
                // self.$store
                //   .dispatch("account/loadList", { status: 1 })
                //   .then(function() {
                // console.log(self.$route)
                if (self.$route.params.id) {
                    self.goToReport(self.$route.params.id)
                } else if (self.$route.name == 'AddExternalTools') {
                    self.addExternalTool()
                } else {
                    self.form = false
                }
                self.$overlay.hide()
                //   });
            } else {
                self.$store.dispatch('account/loadFilters', {}).then(function () {
                    //   self.$store
                    //     .dispatch("account/loadList", { status: 1 })
                    //     .then(function() {
                    if (self.$route.params.id) {
                        self.goToReport(self.$route.params.id)
                    } else if (self.$route.name == 'AddExternalTools') {
                        self.addExternalTool()
                    } else {
                        self.form = false
                    }
                    self.$overlay.hide()
                    // });
                })
            }
        }
    },
    created() {
        this.load()
    },
    watch: {
        $route: 'load'
    },
    mounted() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.external-tool {
    $padding-left: 10px;
    $padding-right: 20px;

    .external-tool-container {
        padding-right: 10px;
        padding-top: 60px;
        height: calc(100% - 20px);
        display: flex;
        align-items: stretch;

        .data-table {
            .v-data-table,
            .v-data-table table {
                .date {
                    display: flex;
                    flex-direction: column;
                    .hour {
                        @include font-size($size: sm);
                        font-family: $text-bold;
                    }
                }
                .status {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }

                .ball {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                }
                .ball-color {
                    display: inline-block;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    margin-right: 3px;
                }
                .image {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    margin: 0 auto;
                }
            }
        }
    }
}
</style>
