<template>
    <div id="content" class="external-tools">
        <div class="bar-actions">
            <Button bType="cancel" :bCallback="goBack" bLabel="user.form.cancel"></Button>
            <Button bType="save" :bCallback="saveExternalTool" customClass="btn-action" :bLabel="$route.params.id ? 'menu_aux.action.save' : 'menu_aux.action.save'"></Button>
            <Button v-if="$route.params.id" bType="more" :bCallback="showMoreOptions"></Button>
            <div class="more-options" v-if="moreOptions">
                <Button bType="delete" :bCallback="deleteExternalTool" bLabel="menu_aux.action.delete" customClass="btn-secondary"></Button>
            </div>
        </div>

        <div class="form-container">
            <div class="form-title">
                <h1 class="form-title">{{ $t('user.form.basic_info') }}</h1>
            </div>
            <div class="label-container">
                <div class="left-container">
                    <h2 class="label-form">{{ $t('product.icon') }}</h2>
                    <p class="description" v-html="$t('external_tools.icon_desc')"></p>
                    <p v-if="emptyAnswer.icon" class="error-form error-field" v-html="$t('external_tools.icon_error')"></p>
                    <div class="user-answer">
                        <div class="icon-changes" v-if="icon">
                            <input type="file" id="icon_selector" style="display: none" ref="icon_product_selector" accept="image/*" @change="previewFiles($event, 'icon_product_selector')" />

                            <div class="product-icon" :style="{ backgroundImage: 'url(' + icon + ')' }"></div>

                            <Button bType="edit" :bCallback="selectIcon" bLabel="product.change_icon" customClass="btn-secondary"></Button>
                            <Button bType="delete" :bCallback="deleteIcon" bLabel="menu_aux.action.delete"></Button>
                        </div>
                        <div class="icon-changes" v-else>
                            <input type="file" id="icon_selector" style="display: none" ref="add_icon_product_selector" accept="image/*" @change="previewFiles($event, 'add_icon_product_selector')" />
                            <div class="product-icon"></div>
                            <Button bType="create" :bCallback="addIcon" bLabel="product.add_icon" customClass="btn-secondary"></Button>
                        </div>
                    </div>
                </div>
                <div class="right-container"></div>
            </div>
            <div class="label-container">
                <div class="left-container">
                    <h2 class="label-form">{{ $t('user.user_name') }}</h2>
                    <p v-if="emptyAnswer.name" class="error-form error-field" v-html="$t('library.form.error_name')"></p>
                    <div class="user-answer">
                        <input type="text" v-model="externalToolRender.name" class="input w85" autocomplete="off" name="name" :placeholder="$t('product.name_placeholder')" />
                    </div>
                </div>
                <div class="right-container">
                    <!-- SWITCH HERE PLS -->
                    <div class="switch">
                        <h2 class="label-form" v-html="statusTool ? $t('sites.form.active') : $t('sites.form.inactive')"></h2>
                        <div class="switch-click" @click="popupStatus()">
                            <input class="switch-input-form" type="checkbox" v-model="statusTool" disabled="true" />
                        </div>
                    </div>
                    <p class="description" v-html="$t('external_tools.switch_desc')"></p>
                </div>
            </div>
            <div class="label-container">
                <div class="left-container align-bottom">
                    <h2 class="label-form">{{ $t('external_tools.access_type') }}</h2>
                    <!-- <p class="description" v-html="$t('product.product_category.priority_desc')"></p> -->
                    <p v-if="emptyAnswer.category" class="error-form error-field" v-html="$t('product.product_category.color_error')"></p>
                    <div class="user-answer select">
                        <v-autocomplete hide-details class="select" v-model="externalToolRender.type" item-text="name" item-value="name" :items="Object.values(this.optionsTypes)" :placeholder="$t('product.category_placeholder')" dense :no-data-text="$t('supervise.filter.no_results')"></v-autocomplete>
                    </div>
                </div>
                <div class="right-container">
                    <h2 class="label-form">
                        {{ externalToolRender.type == 'url' ? $t('external_tools.link') : $t('external_tools.url_intent') }}
                    </h2>
                    <p class="description" v-html="$t('external_tools.access_type_desc')"></p>
                    <p v-if="emptyAnswer.action" class="error-form error-field" v-html="$t('external_tools.general_error')"></p>
                    <div class="user-answer">
                        <input type="text" v-model="externalToolRender.action" class="input w85" autocomplete="off" name="name" />
                    </div>
                </div>
            </div>
            <div class="label-container">
                <div class="left-container align-bottom">
                    <h2 class="label-form">{{ $t('product.product_category.order') }}</h2>
                    <p class="description" v-html="$t('external_tools.order_desc')"></p>
                    <p v-if="emptyAnswer.priority" class="error-form error-field" v-html="$t('product.product_category.order_error')"></p>
                    <div class="user-answer">
                        <input type="number" v-model="externalToolRender.priority" class="input number" autocomplete="off" name="name" />
                    </div>
                </div>
                <div class="right-container"></div>
            </div>
        </div>
        <!-- <div class="inline-delete" v-if="$route.params.id">
      <span class="text" @click="deleteExternalTool()">{{
        $t("external_tools.delete")
      }}</span>
    </div> -->
    </div>
</template>

<script>
import i18n from '@/i18n'
export default {
    name: 'ExternalToolManagement',
    props: { id: undefined },
    components: {},
    data() {
        return {
            filenames: {},
            icon: false,
            statusTool: undefined,
            externalTool: {
                name: '',
                priority: '',
                icon: '',
                status: '1',
                action: '',
                type: 'url'
            },
            category_id: false,
            category: '',
            sendForm: true,
            emptyAnswer: {
                name: false,
                status: false,
                priority: false,
                action: false,
                icon: false
            },
            form: false,
            newImage: false,
            moreOptions: false
        }
    },
    computed: {
        externalToolRender() {
            if (this.id) {
                if (this.$store.getters['account/getList'](this.id) != undefined) {
                    var tool = this.$store.getters['account/getList'](this.id)
                    if (tool.status == '-2') {
                        this.statusTool = false
                    } else if (tool.status == '1') {
                        this.statusTool = true
                    }

                    if (tool.icon != 'false') {
                        this.icon = tool.icon
                    }

                    return tool
                } else {
                    return false
                }
            } else {
                this.statusTool = '1'
                return this.externalTool
            }
        },
        optionsTypes() {
            return this.$store.getters['account/getFilterExternalTools'].type
        }
    },
    methods: {
        showMoreOptions() {
            // METHOD TO SHOW OR HIDE MORE OPTION BUTTONS
            this.moreOptions = !this.moreOptions
        },
        selectIcon() {
            this.$refs.icon_product_selector.click()
        },
        addIcon() {
            this.$refs.add_icon_product_selector.click()
        },
        deleteIcon() {
            this.icon = false
        },
        previewFiles(e, name) {
            e.srcElement.nextSibling.src = '/img/file.svg'
            this.filenames = this.$refs[name].files[0].name
            // this.$overlay.loading();
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            this.createImage(files[0], name)
        },
        // CREATION THUMB
        createImage(file, name) {
            var image = new Image()
            var reader = new FileReader()
            var self = this

            reader.onload = (e) => {
                image.onload = () => {
                    self.icon = resizeImage(image)
                }
                image.src = e.target.result
            }
            reader.readAsDataURL(file)
            this.newImage = true
        },

        onlyNumbers(event) {
            let val = event.target.value.trim()
            // It can only be positive integer or empty, and the rule can be modified according to the requirement.
            if (/^[0-9]\d*$|^$/.test(val)) {
                this.oldNum = val
            } else {
                event.target.value = ''
            }
        },

        popupStatus() {
            var selfThis = this
            if (selfThis.statusTool == '1' || selfThis.statusTool == true) {
                selfThis.$popup.confirm({
                    message: selfThis.$t('external_tools.popup_inactive'),
                    textSave: selfThis.$t('external_tools.popup_confirm'),
                    callSave: function () {
                        selfThis.statusTool = false
                    },
                    callCancel: function () {
                        selfThis.statusTool = true
                    }
                })
            } else {
                selfThis.statusTool = true
            }
        },

        load() {
            var self = this
            if (this.$route.name == 'ExternalTools') {
                if (this.id !== false) {
                    this.$router.push({
                        name: 'EditExternalTools',
                        params: { id: this.id }
                    })
                } else {
                    this.$router.push({ name: 'AddExternalTools' })
                }
            }
        },

        goBack() {
            this.$overlay.show()
            this.$emit('back')
        },
        saveExternalTool() {
            // console.log(this.$refs.groupDataSelected.selection);
            var self = this
            self.$overlay.loading()
            this.sendForm = true
            // set all values of errors to false
            this.emptyAnswer = _.mapValues(this.emptyAnswer, () => false)
            if (this.externalToolRender.name === '') {
                this.sendForm = false
                this.emptyAnswer.name = true
            }
            if (this.externalToolRender.priority === '') {
                this.sendForm = false
                this.emptyAnswer.priority = true
            }
            if (this.externalToolRender.action === '') {
                this.sendForm = false
                this.emptyAnswer.action = true
            }
            if (!this.icon) {
                this.sendForm = false
                this.emptyAnswer.icon = true
            }
            if (this.icon != false) {
                if (this.newImage) {
                    this.externalToolRender.file = this.icon
                } else {
                    this.externalToolRender.image = this.icon
                }
            } else if (!this.icon) {
                this.externalToolRender.image = false
            }
            this.externalToolRender.status = this.statusTool
            if (this.externalToolRender.status == true) {
                this.externalToolRender.status = '1'
            } else if (this.externalToolRender.status == false || this.externalToolRender.status == undefined) {
                this.externalToolRender.status = '-2'
            }

            if (this.sendForm) {
                if (this.$route.params.id) {
                    this.$store.dispatch('account/editExternalTool', this.externalToolRender).then(function (response) {
                        self.goBack()
                        self.$snackbar.open({
                            message: self.$t('snackbar.edit_success') + ' <b>' + self.externalToolRender.name + '</b>',
                            customClass: 'success',
                            duration: 5000
                        })
                    })
                } else {
                    this.$store.dispatch('account/addExternalTool', this.externalToolRender).then(function (response) {
                        self.goBack()
                        self.$snackbar.open({
                            message: self.$t('snackbar.add_success') + ' <b>' + self.externalToolRender.name + '</b>',
                            customClass: 'success',
                            duration: 5000
                        })
                    })
                }
            } else {
                self.$overlay.hide()
                scrollError()
            }
        },
        deleteExternalTool() {
            var self = this
            var name = self.externalToolRender.name
            self.$popup.confirm({
                message: self.$t('external_tools.popup_delete') + self.externalToolRender.name + "'?",
                textSave: self.$t('user.popup.delete_text_save'),
                textCancel: self.$t('user.popup.delete_text_cancel'),
                callSave: function () {
                    self.$store.dispatch('account/deleteExternalTool', self.externalToolRender.id).then(function (response) {
                        self.$emit('back')
                        self.$snackbar.open({
                            message: self.$t('snackbar.remove_success') + ' <b>' + name + '</b>',
                            customClass: 'second',
                            duration: 5000
                        })
                    })
                }
            })
        }
    },
    created() {
        this.load()
    },
    mounted() {},
    watch: {
        icon: {
            deep: true,

            handler() {
                this.$overlay.hide()
            }
        }
    },
    updated() {}
}
</script>

<style lang="scss">
#content.external-tools {
    .form-container {
    }
    .left-container {
        padding-left: 0;
    }
    .product-icon {
        @include background($color: $color-neutral-200, $image: img('product_neutro.svg'), $size: cover);
        @include border-radius(50%);
        width: 60px;
        height: 60px;
    }
    .icon-changes {
        @include display-flex();
        @include align-items();
    }
    .user-answer {
        height: auto !important;
    }

    .prod-assigned-columns {
        @include display-flex();
        @include justify-content(inherit);
        padding-bottom: 30px;
        width: 90%;

        .column {
            @include font-size(md);
            font-family: $text-bold;
            width: 22.5%;
            padding-left: 15px;
        }
    }

    .add-state {
        @include background($image: img('add_neutro_s90.svg'), $size: 9px, $position: center left);
        @include font-size(md);
        font-family: $text-medium;
        width: fit-content;
        padding-left: 15px;
        cursor: pointer;
    }
}
</style>
